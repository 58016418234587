import { Box } from 'native-base';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProgressBar from 'src/components/ProgressBar';
import { IStore } from 'src/redux/reducers';
import { containerStyle } from 'src/styles/containerStyle';

export default function LoginPlaceholder({ navigation }) {

    const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(true);
    const loginStatus = useSelector((store: IStore) => store.login.userLoggedIn);

    useEffect(() => {
        if (loginStatus === "NOT_LOGGED_IN") {
            setShowLoadingIndicator(false)
            navigation.navigate("LoginPage")
        }
    }, [loginStatus]);

    return (
        <Box variant={"main"} style={
            [containerStyle.mainContainer]
        }>
            <ProgressBar isVisible={showLoadingIndicator} progressText="Checking login status..." />
        </Box>
    );
}