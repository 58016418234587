
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadML as upload,
  initializeLabeling,
  setImages,
  setIsExistingDataset,
  fetchDatasetInfo,
  setDatasetId
} from 'src/redux/actions/dataset';
import { IStore } from "src/redux/reducers";
import { Box, Text, Button, Progress, VStack, HStack, Center, Select, CheckIcon } from 'native-base';
import GrainTypeDropdown from 'src/components/dropdown/GrainTypeDropdown';

const ImageUploadPage = ({ navigation }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');
  const dispatch = useDispatch();
  const rejectionMapping = useSelector((state: IStore) => state.dataset.rejectionMapping);

  const datasetId = useSelector((state : IStore) => state.dataset.datasetID);
  const grainId = useSelector((state :IStore) => state.grainType.grainId);
  const uploadProgress = useSelector((state :IStore) => state.dataset.uploadProgress);
  const uploadCompleted = useSelector((state: IStore) => state.dataset.uploadCompleted);

  useEffect(() => {
    if (uploadCompleted && uploadProgress===100) {
      setIsUploading(false);
      setSelectedFiles([]);
      navigation.navigate('DatasetListingPage', { refresh: true });
    }
  }, [uploadCompleted]);

  useEffect(() => {
    if (grainId) {
      dispatch(fetchDatasetInfo.request({ limit: 20, lastKey: null }));
    }
  }, [grainId, dispatch]);

  const handleFileChange = (event: any) => {
    const files: File[] = Array.from(event.target.files as FileList).filter((file: File) => {
      if (!file.type.startsWith('image/')) {
        alert(`File ${file.name} is not an image.`);
        return false;
      }
      return true;
    });
    setSelectedFiles(files);
  };
  const handleUpload = useCallback(() => {
    if (selectedFiles.length > 0 && grainId && datasetId) {
      setIsUploading(true);
      let labelKey = selectedLabel;
      if (!rejectionMapping.hasOwnProperty(selectedLabel)) {
        const mappedKey = getKeyByValue(rejectionMapping, selectedLabel);
        if (mappedKey) {
          labelKey = mappedKey;
        } else {
          alert('Selected label is invalid.');
          setIsUploading(false);
          return;
        }
      }

      dispatch(upload.request({ files: selectedFiles, grainId, datasetId, selectedLabel: labelKey }));

      const request = {
        imageList: selectedFiles.map((img) => ({
          imagePath: `ml-images/${grainId}/${datasetId}/${img.name}`,
          label: labelKey,
        })),
      };
      dispatch(setImages(request.imageList));
    } else {
      alert('Please select files and ensure a dataset and grain type are selected.');
    }
  }, [selectedFiles, dispatch, grainId, datasetId, selectedLabel, rejectionMapping]);

  const handleCreateNewDataset = () => {
    if (grainId) {
      dispatch(setIsExistingDataset(false));
      dispatch(setDatasetId(datasetId));
      dispatch(initializeLabeling.request({ grainId }));
      navigation.navigate('ImageUploadPage');
    } else {
      alert('Please select a grain type before creating a new dataset.');
    }
  };
  return (
    <Center flex={1}>
      <Box
        width="90%"
        maxWidth="400px"
        borderWidth={1}
        borderRadius="md"
        padding={4}
        shadow={2}
      >
        <VStack space={4}>
          <HStack alignItems="center" space={2}>
            <Text fontSize="xl" fontWeight="bold">
              Upload Image(s)
            </Text>
          </HStack>
          <Button onPress={handleCreateNewDataset} mt={4} isDisabled={!grainId}>
            Create New Dataset
          </Button>

          {datasetId && (
            <Text fontSize="sm" color="gray.600" mb={4}>
              Current Dataset ID: {datasetId}
            </Text>
          )}

          <Select
            selectedValue={selectedLabel}
            minWidth="200"
            accessibilityLabel="Choose Label"
            placeholder="Choose Label"
            onValueChange={(itemValue) => setSelectedLabel(itemValue)}
            isDisabled={!rejectionMapping || Object.keys(rejectionMapping).length === 0}
            _selectedItem={{
              bg: 'cyan.600',
              endIcon: <CheckIcon size="5" />,
            }}
          >
            {Object.entries(rejectionMapping).map(([key, value]) => (
              <Select.Item label={value} value={key} key={key} />
            ))}
          </Select>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-button"
            multiple
            type="file"
            onChange={handleFileChange}
            disabled={isUploading || !datasetId || !grainId}
          />
          <label htmlFor="upload-button">
            <Button
              colorScheme="primary"
              variant="outline"
              width="full"
              onPress={() =>
                datasetId && grainId
                  ? (document.getElementById('upload-button') as HTMLElement).click()
                  : null
              }
              isDisabled={isUploading || !datasetId || !grainId}
            >
              Choose Images
            </Button>
          </label>

          {/* Selected Files Info */}
          {selectedFiles.length > 0 && (
            <Text fontSize="sm">{selectedFiles.length} file(s) selected.</Text>
          )}

          {/* Upload Progress */}
          {isUploading && uploadProgress < 100 && (
            <VStack space={2}>
              <Text fontSize="sm">Upload Progress: {uploadProgress}%</Text>
              <Progress value={uploadProgress} size="sm" colorScheme="secondary" />
            </VStack>
          )}

          {/* Upload Button */}
          <Button
            colorScheme="primary"
            onPress={handleUpload}
            isDisabled={
              selectedFiles.length === 0 ||
              isUploading ||
              !datasetId ||
              !grainId ||
              (rejectionMapping && Object.keys(rejectionMapping).length > 0 && !selectedLabel)
            }
          >
            {isUploading ? 'Uploading...' : 'Upload'}
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};
export default ImageUploadPage;

const getKeyByValue = (
  mapping: { [key: string]: string },
  value: string
): string | undefined => {
  return Object.keys(mapping).find((key) => mapping[key] === value);
}
