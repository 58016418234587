import React, { useEffect } from 'react';
import * as AnalyseAction from '../../redux/actions/analyseAction';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import uuid from 'react-native-uuid';
import { useSelector, useDispatch } from "react-redux";
import * as AppActions from '../../redux/actions/appAction';
import { MANUAL_UPLOAD_TIMEOUT, SCAN_TIMEOUT, UPLOAD_TIMEOUT } from '../../constants';
import { IStore } from '../../redux/reducers';
import { IManualJobEntryInput, JobStatus, isImageScanningOrUploading, isScanError } from '../../models';
import MeasurementInfo from 'src/components/MeasurementInfo';
import InfoStatusBar from 'src/components/InfoStatusBar';
import { Auth as Authorization } from 'aws-amplify';
import { Box, Button } from 'native-base';
import CustomAlertDialog from 'src/components/CustomAlertDialog';
import ProgressBar from 'src/components/ProgressBar';
import FabButtons from 'src/components/FabButtons';
import { containerStyle } from 'src/styles/containerStyle';
import { getCurrentLocation } from 'src/utils/location';
import { Modal, Image } from 'native-base';
import { useWindowDimensions } from 'react-native';

export default function InfoPage({ navigation }) {

    // Get react native screen dimensions
    const { width, height } = useWindowDimensions();

    const hideDialog = () => setDialogVisible(false);

    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [dialogStatusMessage, setDialogStatusMessage] = React.useState("");

    const [waiting, setWaiting] = React.useState(false);

    const jobStatus = useSelector((store: IStore) => store.job.currentJobStatus);

    const dispatch = useDispatch();

    interface ImageAsset {
        uri: string;
        [key: string]: any;
    }

    const [selectedImageData, setSelectedImageData] = React.useState<{
        assets: ImageAsset[];
        source: string;
        doubleSided: boolean;
    }>({
        assets: [],
        source: "",
        doubleSided: false,
    });

    const [showPreview, setShowPreview] = React.useState(false);

    useEffect(() => {
        if (isScanError(jobStatus)) {
            displayScanError(jobStatus);
        }
        if (jobStatus === JobStatus.JOB_IMAGE_UPLOADED
            || isScanError(jobStatus)) {
            setWaiting(false);
        }
    }, [jobStatus]);

    const checkAuthAndManualUpload = async (payload: IManualJobEntryInput) => {
        Authorization.currentUserInfo().then(async () => {
            await processManualJob(payload)
        }).catch((e) => {
            console.log('error occurred', e)
            dispatch(AppActions.forceLogout());
        });
    }

    const checkAuthAndUpload = async (data: any, source = "image") => {
        Authorization.currentUserInfo().then(async () => {
            setSelectedImageData({
                assets: data?.assets,
                source: source,
                doubleSided: false
            });
            setShowPreview(true);
        }).catch((e) => {
            console.log('error occurred', e)
            dispatch(AppActions.forceLogout());
        });
    }

    const checkAuthAndDoubleSidedUpload = async (data: any, source = "image") => {
        Authorization.currentUserInfo().then(async () => {
            setSelectedImageData({
                assets: data?.assets,
                source: source,
                doubleSided: true,
            });
            setShowPreview(true);
        }).catch((e) => {
            console.log('error occurred', e)
            dispatch(AppActions.forceLogout());
        });
    }

    const checkAuthAndScan = async () => {
        Authorization.currentUserInfo().then(async () => {
            checkStatusAndStartScan()
        }).catch((e) => {
            console.log('error occurred', e)
            dispatch(AppActions.forceLogout());
        });
    }

    const processManualJob = async (payload: IManualJobEntryInput) => {
        setWaiting(true)
        setTimeout(() => {
            setWaiting(false)
        }, MANUAL_UPLOAD_TIMEOUT)

        const jobId = uuid.v4().toString();
        dispatch(AnalyseAction.initiateManualJob.request({
            jobId: jobId,
            manualJobEntryInput: payload,
            location: await getCurrentLocation()
        }));
    }

    const setResponseAndUploadFile = async (data: any, source = "image") => {
        if (data?.assets === undefined ||
            data?.assets === null ||
            data?.assets.length === 0) {
            return
        }
        setWaiting(true)
        setTimeout(() => {
            setWaiting(false)
        }, UPLOAD_TIMEOUT)
        const jobId = uuid.v4().toString();

        console.log('data', data)

        dispatch(AnalyseAction.initiateUpload.request({
            jobId: jobId,
            isBase64: false,
            dataObject: data?.assets[0],
            source: source,
            location: await getCurrentLocation()
        }));
    }

    const setResponseAndUploadDoubleSidedFile = async (data: any, source = "image") => {
        if (data?.assets === undefined ||
            data?.assets === null ||
            data?.assets.length === 0
            || data?.assets.length === 1) {
            return
        }
        setWaiting(true)
        setTimeout(() => {
            setWaiting(false)
        }, UPLOAD_TIMEOUT)
        const jobId = uuid.v4().toString();
        dispatch(AnalyseAction.initiateDoubleSidedUpload.request({
            jobId: jobId,
            isBase64: false,
            dataObject: data?.assets,
            source: source,
            location: await getCurrentLocation()
        }));
    }

    const checkStatusAndStartScan = async () => {
        setWaiting(true)
        setTimeout(() => {
            setWaiting(false)
        }, SCAN_TIMEOUT)

        const jobId = uuid.v4().toString();

        dispatch(AnalyseAction.initiateScan.request({
            jobId: jobId,
            source: "image",
            location: await getCurrentLocation()
        }));
    }

    const displayScanError = (errorType: string) => {
        if (errorType === JobStatus.WIFI_NOT_CONNECTED) {
            setDialogVisible(true);
            setDialogStatusMessage("Wifi is not connected on the GRAMS IoT device. Please connect it to a Wifi network and try again.");
        } else if (errorType === JobStatus.SCANNER_NOT_READY) {
            setDialogVisible(true);
            setDialogStatusMessage("Scanner is not ready. Please check if it is connected and powered on.");
        } else if (errorType === JobStatus.IOT_NOT_CONNECTED) {
            setDialogVisible(true);
            setDialogStatusMessage("GRAMS IoT device is not connected. Please connect it to your computer and try again.");
        } else if (errorType === JobStatus.JOB_IMAGE_SCAN_ERROR) {
            setDialogVisible(true);
            setDialogStatusMessage("Error occured while scanning image. Please check scanner and try again.");
        }
    }

    const getProgressBarMessage = () => {
        if (jobStatus === JobStatus.JOB_IMAGE_SCANNING) {
            return 'Scanning image...';
        } else if (
            jobStatus === JobStatus.JOB_IMAGE_UPLOADING) {
            return 'Uploading image...';
        } else if (jobStatus === JobStatus.JOB_CREATING_MANUAL_ENTRY) {
            return 'Processing...';
        }
        return "Processing...";
    }

    const isProgressBarVisible = () => {
        const waitingForResult = isImageScanningOrUploading(jobStatus);

        console.log('jobStatus', jobStatus, 'waitingForResult', waitingForResult, 'waiting', waiting)

        if (waitingForResult && waiting) {
            return true
        }
        return false
    }

    const imageUploadButtonClick = () => {
        if (isProgressBarVisible()) {
            console.log('Scan or upload in progress')
            return
        }
        launchImageLibrary({
            selectionLimit: 0,
            mediaType: 'photo',
            includeBase64: false,
        }, (data) => checkAuthAndUpload(data, "image"))
    }

    const doubleSidedImageUploadButtonClick = () => {
        if (isProgressBarVisible()) {
            console.log('Scan or upload in progress')
            return
        }
        launchImageLibrary({
            selectionLimit: 2,
            mediaType: 'photo',
            includeBase64: false,
        }, (data) => checkAuthAndDoubleSidedUpload(data, "image"))
    }

    const imageCaptureButtonClick = () => {
        if (isProgressBarVisible()) {
            console.log('Scan or upload in progress')
            return
        }
        launchCamera({
            mediaType: 'photo',
            includeBase64: false,
            saveToPhotos: true,
        }, (data) => checkAuthAndUpload(data, "camera"))
    }

    const manualEntryButtonClicked = async (payload: IManualJobEntryInput) => {
        if (isProgressBarVisible()) {
            console.log('Scan or upload in progress')
            return
        }

        await checkAuthAndManualUpload(payload);
    }

    const uploadSelectedImage = () => {
        // Function to upload the selected image
        if (!selectedImageData
            || !selectedImageData.assets
            || selectedImageData.assets.length === 0
        ) {
            return
        }

        if (selectedImageData.doubleSided) {
            setResponseAndUploadDoubleSidedFile(selectedImageData, selectedImageData.source)
        } else {
            setResponseAndUploadFile(selectedImageData, selectedImageData.source)
        }
    }

    return (
        <>
            <Modal
                size={"full"}
                isOpen={showPreview}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>Image Preview</Modal.Header>
                    <Modal.Body>
                        <Box>
                            <Image
                                alt='Image Preview'
                                source={{ uri: selectedImageData?.assets[0]?.uri }}
                                style={{
                                    width: width,
                                    height: height - 200,
                                    resizeMode: 'contain',
                                }} />
                        </Box>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                                setSelectedImageData({
                                    assets: [],
                                    source: "",
                                    doubleSided: false,
                                })
                                setShowPreview(false);
                            }}>
                                Cancel
                            </Button>
                            <Button
                                onPress={async () => {
                                    setShowPreview(false);
                                    uploadSelectedImage();
                                }
                                }>Upload</Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <Box variant={"main"} style={[containerStyle.mainContainer, {
                height: '100%',
            }]}>
                <CustomAlertDialog
                    title='Grams IoT'
                    description={dialogStatusMessage}
                    cancelText='Cancel'
                    confirmText='Continue'
                    isOpen={dialogVisible}
                    onCancelClicked={hideDialog}
                    onConfirmClicked={() => {
                        hideDialog();
                        navigation.push('ConnectToRpi', {
                            returnBackTo: 'Home',
                        })
                    }}
                />
                <InfoStatusBar navigation={navigation} />
                <Box style={{
                    marginTop: 20,
                }}>
                    {
                        isProgressBarVisible() &&
                        <ProgressBar
                            isVisible={isProgressBarVisible()}
                            withoutOverlay={true}
                            progressText={getProgressBarMessage()}
                            progressSubText={"Analyze another sample after upload finishes. Meanwhile, you can fill out the job info."} />
                    }
                </Box>
                <MeasurementInfo />
                <FabButtons
                    isDisabled={isProgressBarVisible()}
                    scanImageClicked={async () => await checkAuthAndScan()}
                    imageUploadClicked={imageUploadButtonClick}
                    captureImageClicked={imageCaptureButtonClick}
                    doubleSidedImageUploadClicked={doubleSidedImageUploadButtonClick}
                    manualEntryClicked={async (payload: IManualJobEntryInput) => {
                        await manualEntryButtonClicked(payload)
                    }}
                />
            </Box>
        </>
    );
}