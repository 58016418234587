import React, { useState, useEffect } from 'react';
import { ScrollView, VStack, Button, Text, HStack } from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import * as TrainingActions from 'src/redux/actions/trainingAction';
import TrainingJobCreationModal from 'src/components/modal/NewJobModal';
import UpdateJobModal from 'src/components/modal/UpdateJobModal';
import TrainingJobsList from 'src/components/list/TrainingJobsList';
import { IStore } from 'src/redux/reducers';

const CreateTrainingJobPage = ({ navigation }: { navigation: any }) => {
  const dispatch = useDispatch();
  const selectedGrainId = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
  const { trainingJobs = [], loading: trainingLoading } = useSelector((state: IStore) => state.training);
  const [modalVisible, setModalVisible] = useState({ newJob: false, updateJob: false });
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    dispatch(TrainingActions.fetchAllTrainingJobs.request());
  }, [dispatch]);

  return (
    <ScrollView>
      <VStack space={4} p={5}>
        <HStack justifyContent="space-between" alignItems="center">
          <Text fontSize="xl" fontWeight="bold">Training Jobs</Text>
          <Button onPress={() => setModalVisible(prev => ({ ...prev, newJob: true }))}>
            Add New Job
          </Button>
        </HStack>

        {modalVisible.newJob && (
          <TrainingJobCreationModal
            isOpen={modalVisible.newJob}
            onClose={() => setModalVisible(prev => ({ ...prev, newJob: false }))}
            selectedGrainId={selectedGrainId}
            dispatch={dispatch}
            navigation={navigation}
          />
        )}
        
        <TrainingJobsList
          jobs={trainingJobs}
          loading={trainingLoading}
          onUpdateJob={(job) => {
            setSelectedJob(job);
            setModalVisible(prev => ({ ...prev, updateJob: true }));
          }}
          onCopyJobId={(jobID) => navigator.clipboard.writeText(jobID)}
        />

        {modalVisible.updateJob && selectedJob && (
          <UpdateJobModal
            job={selectedJob}
            onClose={() => {
              setModalVisible(prev => ({ ...prev, updateJob: false }));
              setSelectedJob(null);
            }}
            onCopyJobId={(jobID) => navigator.clipboard.writeText(jobID)}
            dispatch={dispatch}
          />
        )}
      </VStack>
    </ScrollView>
  );
};

export default CreateTrainingJobPage;