import * as React from 'react';
import { Platform } from 'react-native';
import GrainTypeDropdown from '../../components/dropdown/GrainTypeDropdown';
import GrainProfileDropdown from '../../components/dropdown/GrainProfileDropdown';
import { useSelector } from "react-redux";
import { Box, Button } from "native-base";
import { IStore } from '../../redux/reducers';
import { containerStyle } from 'src/styles/containerStyle';
import ProgressBar from 'src/components/ProgressBar';

export default function ChooseGrainProfilePage({ route, navigation }) {
    const isFetchingGrainType = useSelector((store: IStore) => store.user.userProfile.isFetching);

    const handleSaveProfile = () => {
        if (Platform.OS === 'web') {
            navigation.navigate('Home');
        } else {
            if (route.params !== undefined && route.params.returnBackTo !== undefined) {
                navigation.navigate(route.params.returnBackTo);
            } else {
                navigation.navigate('ConnectToRpi');
            }
        }
    }

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <ProgressBar isVisible={isFetchingGrainType || false} progressText="Loading..." />
            <GrainTypeDropdown />
            <GrainProfileDropdown />
            <Button style={{
                marginTop: 24,
            }} onPress={handleSaveProfile}>SAVE PROFILE</Button>
        </Box>
    );
}