import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Button, VStack, Text, Spinner, HStack, ScrollView, Progress } from 'native-base';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { fetchData, handleSaveProgress, handleCompleteLabeling } from 'src/components/ml/labelingUtils';
import { processImages } from 'src/components/ml/imageUtils';
import Pagination from 'src/components/Pagination';
import NoImagesAvailable from 'src/components/NoImagesAvailable';
import { fetchRejectionMapping, setUploadProgress, uploadComplete} from 'src/redux/actions/dataset';
import { IStore } from 'src/redux/reducers';
import ImageTable from 'src/components/ml/ImageTable';
import { IImageItem } from 'src/models/UploadImages';

const ITEMS_PER_PAGE = 26;

import { NavigationProp } from '@react-navigation/native';

interface CombinedLabelingPageProps {
    navigation: NavigationProp<any>;
}

const CombinedLabelingPage = ({ navigation }: CombinedLabelingPageProps) => {
    const dispatch = useDispatch();
    const isFocused = useIsFocused();

    const { isJob, datasetId, jobId, images, jobData, jobDetails, isExistingDataset, rejectionMapping, grainId, uploadProgress, uploadCompleted   
    } = useSelector((state: IStore) => ({
        isJob: state.dataset.isJob,
        datasetId: state.dataset.isJob ? state.dataset.datasetID : state.dataset.id,
        jobId: state.dataset.isJob ? state.dataset.id : state.job.jobStatus.job?.jobId,
        images: state.dataset.images,
        jobData: state.job.jobStatus.job,
        jobDetails: state.dataset.jobDetails,
        isExistingDataset: state.dataset.isExistingDataset,
        rejectionMapping: state.dataset.rejectionMapping,
        grainId: state.grainType.grainId,
        uploadProgress: state.dataset.uploadProgress,
        uploadCompleted: state.dataset.uploadCompleted
    }), shallowEqual);

    const [processedImages, setProcessedImages] = useState<IImageItem[]>([]);
    const [changedLabels, setChangedLabels] = useState({});
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [noImagesAvailable, setNoImagesAvailable] = useState(false);

    // Fetch images once and store them in state
    const fetchImagesData = useCallback(async () => {
        try {
            const newImages = await processImages(
                isJob,
                jobData ?? {},
                jobDetails,
                images,
                jobId ?? '',
                datasetId ?? '',
                isExistingDataset ?? false,
                isDataFetched,
            );

            if (newImages.length > 0) {
                setProcessedImages(newImages);
                setTotalPages(Math.ceil(newImages.length / ITEMS_PER_PAGE));
                setNoImagesAvailable(false);
                setChangedLabels({}); // Reset labels on data fetch
            } else {
                setNoImagesAvailable(true);
            }
        } catch (error) {
            console.error('Error in fetchImagesData:', error);
            setNoImagesAvailable(true);
        }
    }, [isJob, jobData, jobDetails, images, jobId, datasetId, isExistingDataset, isDataFetched]);

    useEffect(() => {
        if (isDataFetched && isFocused) {
            fetchImagesData();
        }
    }, [isDataFetched, fetchImagesData, isFocused]);

    useEffect(() => {
        if (!isFocused) {
          dispatch(setUploadProgress(0));
          dispatch(uploadComplete(false));
        }
      }, [isFocused, dispatch]);

    // Consolidated Navigation Logic
    useEffect(() => {
        if (uploadCompleted && uploadProgress === 100) {
          navigation.navigate('DatasetListingPage', { refresh: true });
        }
      }, [uploadCompleted, uploadProgress, navigation]);

    // Fetch dataset or job data when focused
    useFocusEffect(
        useCallback(() => {
            if (datasetId || jobId) {
                setIsDataFetched(false);
                dispatch(fetchRejectionMapping.request({ grainId: grainId ?? '' }));
                fetchData(dispatch, isJob, jobId ?? null, datasetId ?? null, isExistingDataset ?? false, setIsDataFetched);
            }
        }, [datasetId, jobId, dispatch, isJob, isExistingDataset, grainId])
    );

    useEffect(() => {
        setProcessedImages([]);
        setChangedLabels({});
        setPage(0);
        setTotalPages(1);
        setNoImagesAvailable(false);
    }, [datasetId, jobId]);

    const handleLabelChange = useCallback((imagePath: string, newLabel: string) => {
        setChangedLabels((prevState) => ({
          ...prevState,
          [imagePath]: newLabel,
        }));
      }, []);

    // Paginate images without fetching again
    const paginatedImages = useMemo(() => {
        return processedImages.slice(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE);
    }, [page, processedImages]);

    if (!isFocused) {
        return null;
    }

    if (!datasetId && !jobId) {
        return <NoImagesAvailable navigation={navigation} />;
    }

    if (noImagesAvailable && (uploadProgress > 0)) {
        return (
            <Box flex={1} justifyContent="center" alignItems="center">
                <Spinner accessibilityLabel="Loading datasets" />
                <Text mt={4}>Loading images...</Text>
            </Box>
        );
    }

    return (
        <Box flex={1} p={5}>
            <ScrollView flex={1}> 
                {/* Move the ProgressBar here to always show it when uploading */}
                {uploadProgress > 0 && uploadProgress < 100 && (
                    <Box mb={4}>
                        <HStack alignItems="center">
                            <Progress value={uploadProgress} flex={1} mr={2} />
                            <Text>{`${uploadProgress}%`}</Text>
                        </HStack>
                    </Box>
                )}
                <VStack space={2} flexGrow={1}>
                    <HStack justifyContent="space-between" alignItems="center">
                        <Text fontSize="xl" fontWeight="bold">Label Images</Text>
                        <Text>{isJob ? `Job ID: ${jobId}` : `Dataset ID: ${datasetId}`}</Text>
                    </HStack>
                    {paginatedImages.length > 0 ? (
                        <ImageTable
                            images={paginatedImages}
                            changedLabels={changedLabels}
                            rejectionMapping={rejectionMapping}
                            onLabelChange={handleLabelChange}
                            isExistingDataset={isExistingDataset}
                        />
                    ) : (
                            <Box flex={1} justifyContent="center" alignItems="center">
                                <Text fontSize="lg" fontWeight="bold">Loading...</Text>
                            </Box>
                    )}
                    <Box mt={4}>
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            onPageChange={setPage}
                        />
                    </Box>
                    <HStack justifyContent="space-between" mt={4}>
                        <Button flex={1} onPress={() => navigation.goBack()}>
                            Return to Dashboard
                        </Button>
                        <Button flex={1} ml={2} onPress={() => handleCompleteLabeling(dispatch, isJob, isExistingDataset ?? false, jobId ?? '', datasetId ?? '', grainId ?? '', changedLabels, processedImages, navigation)}>
                            Complete Labeling
                        </Button>
                        <Button
                         flex={1} 
                         ml={2} 
                         onPress={async () => {
                             await handleSaveProgress(dispatch, isJob, isExistingDataset ?? false, jobId ?? '', datasetId ?? '', grainId ?? '', changedLabels, processedImages, navigation);
                             window.alert('Saved: Your progress has been saved successfully.');
                             setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
                         }}
                         >
                            Save & Next
                        </Button>
                    </HStack>
                </VStack>
            </ScrollView>
        </Box>
    );
};

export default CombinedLabelingPage;