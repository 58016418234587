import * as React from 'react';
import GrainTypeDropdown from '../../../components/dropdown/GrainTypeDropdown';
import { useSelector } from "react-redux";
import { Box, Button } from "native-base";
import { IStore } from '../../../redux/reducers';
import { containerStyle } from 'src/styles/containerStyle';
import ProgressBar from 'src/components/ProgressBar';

export default function ChooseGrainTypePage({ route, navigation }) {
    const isFetchingGrainType = useSelector((store: IStore) => store.user.userProfile.isFetching);

    const handleSaveProfile = () => {
        navigation.navigate('MlDashBoardPage');
    }

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <ProgressBar isVisible={isFetchingGrainType || false} progressText="Loading..." />
            <GrainTypeDropdown all={true}/>
            <Button style={{
                marginTop: 24,
            }} onPress={handleSaveProfile}>SAVE GRAIN</Button>
        </Box>
    );
}