import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Auth as Authorization } from 'aws-amplify';
import * as LoginActions from '../../redux/actions/loginAction';
import { useDispatch } from 'react-redux';
import { Box, Button, FormControl, Input, VStack } from 'native-base';
import { containerStyle } from 'src/styles/containerStyle';
import { inputStyle } from 'src/styles/inputStyle';
import ProgressBar from 'src/components/ProgressBar';
import InweonLogo from 'src/components/InweonLogo';
import { formProps } from 'src/styles/formProps';

interface ConfirmSignupFormState {
    confirmationCode: string
}

export default function ConfirmSignupPage({ route }) {

    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

    const [formData, setData] = React.useState<ConfirmSignupFormState>({
        confirmationCode: '',
    });

    const [errors, setErrors] = React.useState({});

    const validate = () => {
        if (formData.confirmationCode === undefined
            || formData.confirmationCode.length === 0) {
            setErrors({
                confirmationCode: 'Confirmation code is required'
            });
            return false;
        }
    };

    const dispatch = useDispatch();

    const handleSignupConfirmation = async () => {
        setShowLoadingIndicator(true)
        Authorization.confirmSignUp(route.params.username, formData.confirmationCode)
            .then(async (resp) => {
                if (resp !== undefined) {
                    dispatch(LoginActions.setUserLoggedIn("SIGNED_UP"));
                    setShowLoadingIndicator(false)
                }
            }).catch((e) => {
                console.log('error occurred', e)
                setErrors({
                    formError: e.message
                });
                setShowLoadingIndicator(false)
            });
    }

    const onSubmit = () => {
        const isValid = validate();
        if (isValid) {
            // submit the data
            handleSignupConfirmation()
        }
    };

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <ProgressBar isVisible={showLoadingIndicator} progressText="Completing sign up..." />
            <ScrollView automaticallyAdjustKeyboardInsets={true}>
                <InweonLogo title="Confirm your account" />
                <VStack width={formProps.width}
                    style={containerStyle.formContainer} mx="3">
                    <FormControl isRequired>
                        <FormControl.Label>Confirmation Code</FormControl.Label>
                        <Input
                            style={inputStyle.nativeInput}
                            isInvalid={'confirmationCode' in errors}
                            placeholder="********"
                            onSubmitEditing={onSubmit}
                            onChangeText={value => setData({
                                ...formData,
                                confirmationCode: value
                            })} />
                        {'confirmationCode' in errors ? <FormControl.ErrorMessage>{errors['confirmationCode']}</FormControl.ErrorMessage> : null}
                    </FormControl>
                    <Button onPress={onSubmit} mt="5">
                        Confirm Sign Up
                    </Button>
                    {'formError' in errors ? <Text color="red.500" mt="2">{errors['formError'] || ""}</Text> : null}
                </VStack>
            </ScrollView>

        </Box>
    );
}