import * as React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/reducers';
import { JobStatus } from '../../models';
import { Box, Center, Text, View } from 'native-base';
import ZoomableImage from '../../components/ZoomableImage';
import { useIsFocused } from '@react-navigation/native';
import { containerStyle } from 'src/styles/containerStyle';
import { getText } from 'src/utils/i18n';

export default function ImagePage() {
    const job = useSelector((store: IStore) => store.job.jobStatus.job);
    const productType = useSelector((store: IStore) => store.job.jobStatus.job?.productName)
        || "GRAIN_ANALYSIS";
    
    const jobStatus = useSelector((store: IStore) => store.job.currentJobStatus);
    const isFocused = useIsFocused();

    if (!isFocused) {
        return <Box variant={"main"} style={containerStyle.mainContainer}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ padding: 12, textAlign: 'center' }}>Loading...</Text>
            </View>
        </Box>
    }

    return (
        (jobStatus === JobStatus.JOB_COMPLETED
            && (productType !== "L10_GRAIN_ANALYSIS")
            && (productType !== "MANUAL_GRAIN_ANALYSIS")
            && job) ? (<>
                <ZoomableImage imgSrc={job.results?.labeled_image} imgHeight={2048} imgWidth={1440} priority={'normal'} cache={false} timestamp={job.updatedAt || job.createdAt} />
            </>) : ((productType !== "L10_GRAIN_ANALYSIS"
                && productType !== "MANUAL_GRAIN_ANALYSIS"
            ) ? (
                <>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ padding: 12, textAlign: 'center' }}>{getText('selectImageTabNotice')}</Text>
                    </View>
                </>) : (
                <>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ padding: 12, textAlign: 'center' }}>{getText('imgNotAvlNotice')}</Text>
                    </View>
                </>
            )
        ))

}